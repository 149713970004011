import React from "react";
import LocalizedLink from "../../components/localizedLink";

const CatalogDownloadList = (props) => {
	return (
		<li className="catalog-li ">
			<a className={"medium"} href={props.link} target={"_blank"} rel="noreferrer">
				<div className="catalog-list flex">
					<div className="catalog-list-img">
						<img src={props.image} alt={props.alt} />
					</div>
					<div className="catalog-list-content">
						<div className="catalog-list-content-title">
							<p>{props.title}</p>
						</div>
						<div className="catalog-list-content-info">
							<p>{props.catalog_list_info_page}</p>
							<p>{props.catalog_list_info_date}</p>
							<p>{props.catalog_list_info_update}</p>
							{ props.sk_link &&
								<a href={props.sk_link} className="skindex" target={"_blank"} rel="noreferrer">
									{props.catalog_list_sk_link}
								</a>
							}
						</div>
					</div>
				</div>
			</a>
		</li>
	);
};
export default CatalogDownloadList;
